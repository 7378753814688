import _ from 'lodash';
import moment from 'moment';

export const catchFunction = (err) => {
    console.log('catchFunction=',err)
    if (err.response) {
        const { data } = err.response;
        if (data?.errors) {
            const errorMessage = _.values(data.errors).some((arr) => arr.length > 0) ? _.first(_.flatten(_.values(data.errors))) : data.message;
            return errorMessage;
        } else {
            if(data.message){
                return data.message;
            }
            return 'An error occurred. Please try again later.';
        }
    } else if (err.request) {
        return 'Network error. Please check your internet connection.';
    } else {
        console.error('Error:', err.message);
        return 'An error occurred. Please try again later.';
    }
}

export const formatDateTime = (dateTime, formatStr = 'DD MMM YYYY, hh:mm A') => {
    if(!dateTime){
        return null;
    }
    const d = moment(dateTime).format(formatStr);
    return d
}

export const formatDateTimeFromISODate = (dateTime, formatStr = 'DD MMM YYYY, hh:mm A') => {
    if(!dateTime){
        return null;
    }
    const d = moment(Number(dateTime)).format(formatStr);
    return d
}

export const shortenFilename = (filename, maxLength) => {
    const extension = filename.split('.').pop();
    const basename = filename.substring(0, filename.lastIndexOf('.'));

    if (basename.length <= maxLength) {
        return filename;
    }

    const start = basename.slice(0, maxLength / 2);
    const end = basename.slice(-maxLength / 2);

    return `${start}...${end}.${extension}`;
}

export const hideObjectFromArrayByKey = (fields, keysToHide = [], hasPermission) => {
    if (!hasPermission) {
        return fields.filter(field => !keysToHide.includes(field.key));
    }
  
    return fields;
};

export const s3URL = () => {
    return "https://zape02.s3.ap-southeast-1.amazonaws.com/";
};

export const isTopicAccess = (topics, topicId) => {
    return topics.length > 0?topics.includes(topicId):false;
};

export const noPermission = (str = null) => {
    if(str){
        return `
            <div class="mt-5 mb-5 d-flex justify-content-center align-items-center">
                <div class="text-center">${str}</div>
            </div>
        `;
    }
    return `
        <div class="mt-5 mb-5 d-flex justify-content-center align-items-center">
            <div class="text-center">You do not have permission to access this page</div>
        </div>
    `;
};
  

export default {
    catchFunction,
    formatDateTime,
    shortenFilename,
    hideObjectFromArrayByKey,
    s3URL,
    isTopicAccess,
    noPermission,
}